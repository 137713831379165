import { ApiCreationTypeEnum, PublishingStateEnum } from "@/api/common-types";
import {
  declarationSchemaForInput,
  getObjectTypeArraysDiff,
  publishApi,
} from "@/models";
import { Component } from "vue-property-decorator";

import { isUnchangedLeaf } from "@/utils";

import ApiPublish from "@/components/api-management/ApiPublish.component";
import ApiPublishedTypes from "@/components/api-management/ApiPublishedTypes.mixin";
import { enumsDiffToChangesList } from "@/components/diff/enums-diff-to-changes-list";
import { objectTypesDiffToChangesList } from "@/components/diff/object-types-diff-to-changes-list";
import { getEnumArraysDiff } from "@/models/enum";

@Component
export default class ProjectSetupOverview extends ApiPublishedTypes {
  private isLoading = false;
  private errorMessage = "";
  private creationType = ApiCreationTypeEnum.PUBLISH;

  private get isPublished() {
    return this.apiData?.publishRuns && this.apiData.publishRuns.length > 0;
  }

  private get closeButtonString() {
    return this.isApiUnpublished
      ? this.$t("global_cta_save_free").toString()
      : this.$t("global_cta_cancel").toString();
  }

  private get isApiUnpublished(): boolean {
    return this.apiData?.state === PublishingStateEnum.INITIALIZED;
  }

  private get ctaButtonText() {
    if (this.isPublished) return this.$t("global_cta_publish").toString();

    return this.creationType === ApiCreationTypeEnum.PUBLISH
      ? this.$t("global_cta_publish").toString()
      : this.$t("global_cta_import").toString();
  }

  private get headerText() {
    if (this.isPublished) return this.$t("api_setup_overview_headline_publish");
    return this.$t("api_setup_overview_headline_import");
  }

  private async onSubmit(): Promise<void> {
    if (!this.apiData) return;
    try {
      this.isLoading = true;
      await this.updateSchema(declarationSchemaForInput(this.apiData));

      if (this.creationType === ApiCreationTypeEnum.PUBLISH) {
        await publishApi(this.apiData, this.apiData.state, []);
        this.$router.push({
          name: "project-setup-publishing",
          params: { apiId: this.apiData.id },
        });
      } else if (this.creationType === ApiCreationTypeEnum.IMPORT) {
        this.$router.push({
          name: "project-overview",
          params: { apiId: this.apiData.id },
        });
      }
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isLoading = false;
    }
  }

  private get enumsChangesList() {
    const enumTypesDiffResult = getEnumArraysDiff(
      this.publishedTypes.enumTypes,
      this.unpublishedTypes.enumTypes
    );

    if (!this.isApiUnpublished && !isUnchangedLeaf(enumTypesDiffResult)) {
      return enumsDiffToChangesList(enumTypesDiffResult);
    }
    return undefined;
  }

  private get objectTypesChangesList() {
    const objectTypesDiffResult = getObjectTypeArraysDiff(
      this.publishedTypes.objectTypes,
      this.unpublishedTypes.objectTypes
    );

    if (!this.isApiUnpublished && !isUnchangedLeaf(objectTypesDiffResult)) {
      return objectTypesDiffToChangesList(
        objectTypesDiffResult,
        this.unpublishedTypes.objectTypes
      );
    }
    return undefined;
  }

  render() {
    return (
      <ApiPublish
        errorMessage={this.errorMessage}
        ctaButtonText={this.ctaButtonText}
        cancelButtonText={this.closeButtonString}
        onSubmit={this.onSubmit}
        enumsChangesList={this.enumsChangesList}
        objectTypesChangesList={this.objectTypesChangesList}
        isLoading={this.isLoading}
      >
        <template slot="header">{this.headerText}</template>
      </ApiPublish>
    );
  }
}
