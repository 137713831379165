import {
  ApiCreationTypeEnum,
  PublishingStateEnum,
  RoleTypeEnum,
} from "@/api/common-types";
import appsIconLight from "@/assets/img/nav-icon-apps-black.svg";
import appsIconDark from "@/assets/img/nav-icon-apps-color.svg";
import assetsLight from "@/assets/img/nav-icon-assets-light.svg";
import assetsDark from "@/assets/img/nav-icon-assets.svg";
import cloudFunctionsLight from "@/assets/img/nav-icon-cloud-light.svg";
import cloudFunctionsDark from "@/assets/img/nav-icon-cloud.svg";
import contentLight from "@/assets/img/nav-icon-content-light.svg";
import contentDark from "@/assets/img/nav-icon-content.svg";
import flowsIconLight from "@/assets/img/nav-icon-flows-black.svg";
import flowsIconDark from "@/assets/img/nav-icon-flows-color.svg";
import historyLight from "@/assets/img/nav-icon-history-light.svg";
import historyDark from "@/assets/img/nav-icon-history.svg";
import objectsLight from "@/assets/img/nav-icon-objects-light.svg";
import objectsDark from "@/assets/img/nav-icon-objects.svg";
import playgroundLight from "@/assets/img/nav-icon-playground-light.svg";
import playgroundDark from "@/assets/img/nav-icon-playground.svg";
import settingsLight from "@/assets/img/nav-icon-settings-light.svg";
import settingsDark from "@/assets/img/nav-icon-settings.svg";
import i18n from "@/i18n";
import store from "@/store/store";
import { isAlpha } from "@/utils";
import {
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";

export interface INavItem {
  text: string;
  link: { name: string; params?: { [key: string]: string } };
  icon?: {
    dark: string;
    light: string;
  };
}

export interface INavSection {
  name: string;
  items: INavItem[];
}

export interface INavState {
  currentApiName: string;
  currentApiCreationType: ApiCreationTypeEnum;
}
@Module({ dynamic: true, store, name: "nav" })
class Nav extends VuexModule implements INavState {
  currentApiName = "";
  currentApiId = "";
  currentApiCreationType = ApiCreationTypeEnum.PUBLISH;
  isCognitoApi = false;
  hasApiGateway = false;

  get showDevTools() {
    return (): boolean =>
      this.currentApiCreationType === ApiCreationTypeEnum.PUBLISH &&
      this.isCurrentApiPublished &&
      [
        RoleTypeEnum.SUPERUSER,
        RoleTypeEnum.ADMIN,
        RoleTypeEnum.OWNER,
        RoleTypeEnum.DEV,
      ].includes(store.state.user.roles[store.state.org.id]?.type);
  }

  get isSuperUser() {
    return (): boolean =>
      [RoleTypeEnum.SUPERUSER].includes(
        store.state.user.roles[store.state.org.id]?.type
      );
  }

  get isCurrentApiPublished() {
    const apiPublishingState = store.state.graphapi.states[this.currentApiId];
    return (
      apiPublishingState &&
      apiPublishingState !== PublishingStateEnum.INITIALIZED &&
      apiPublishingState !== PublishingStateEnum.IMPORTED
    );
  }

  get isCurrentApiImported() {
    return this.currentApiCreationType === ApiCreationTypeEnum.IMPORT;
  }

  get apiDetailsNavItems(): INavSection[] {
    return [
      ...this.apiDetailsDefaultNavItems,
      ...(this.showDevTools() || this.isCurrentApiImported
        ? this.apiDetailsDevtoolsNavItem
        : []),
      {
        name: i18n.t("nav_sidebar_title_general").toString(),
        items: [
          ...(this.isCurrentApiPublished
            ? [
                {
                  text: i18n.t("nav_sidebar_item_logs").toString(),
                  link: { name: "project-logs" },
                  icon: {
                    dark: historyDark,
                    light: historyLight,
                  },
                },
              ]
            : []),
          {
            text: `${i18n.t("global_settings")}`,
            link: { name: "project-settings" },
            icon: {
              dark: settingsDark,
              light: settingsLight,
            },
          },
        ],
      },
    ];
  }

  get apiDetailsDefaultNavItems(): INavSection[] {
    return [
      {
        name: i18n.t("nav_sidebar_title_cms").toString(),
        items: [
          {
            text: i18n.t("nav_sidebar_item_data").toString(),
            link: { name: "project-data" },
            icon: {
              dark: contentDark,
              light: contentLight,
            },
          },
          ...(!this.isCurrentApiImported
            ? [
                {
                  text: i18n.t("nav_sidebar_item_assets").toString(),
                  link: { name: "project-assets" },
                  icon: {
                    dark: assetsDark,
                    light: assetsLight,
                  },
                },
                ...(isAlpha() || this.isSuperUser()
                  ? [
                      {
                        text: i18n.t("nav_sidebar_item_flows").toString(),
                        link: { name: "project-flows" },
                        icon: {
                          dark: flowsIconDark,
                          light: flowsIconLight,
                        },
                      },
                    ]
                  : []),
                {
                  text: i18n.t("nav_sidebar_item_apps").toString(),
                  link: { name: "project-apps" },
                  icon: {
                    dark: appsIconDark,
                    light: appsIconLight,
                  },
                },
                {
                  text: i18n.t("nav_sidebar_item_models").toString(),
                  link: { name: "project-types" },
                  icon: {
                    dark: objectsDark,
                    light: objectsLight,
                  },
                },
              ]
            : []),
        ],
      },
    ];
  }

  get apiDetailsDevtoolsNavItem(): INavSection[] {
    return [
      {
        name: i18n.t("nav_sidebar_title_devs").toString(),
        items: [
          ...(!this.isCurrentApiImported
            ? [
                {
                  text: i18n.t("nav_sidebar_item_cloud_functions").toString(),
                  link: { name: "project-cloud-functions" },
                  icon: {
                    dark: cloudFunctionsDark,
                    light: cloudFunctionsLight,
                  },
                },
              ]
            : []),
          {
            text: i18n.t("nav_sidebar_item_queries").toString(),
            link: { name: "project-queries" },
            icon: {
              dark: playgroundDark,
              light: playgroundLight,
            },
          },
        ],
      },
    ];
  }

  @Mutation
  setCurrentApiId(apiId: string): void {
    this.currentApiId = apiId;
  }

  @Mutation
  setCurrentApiName(name: string): void {
    this.currentApiName = name;
  }

  @Mutation
  setCurrentApiCreationType(creationType: ApiCreationTypeEnum): void {
    this.currentApiCreationType = creationType;
  }

  @Mutation
  setIsCognitoApi(isCognitoApi: boolean): void {
    this.isCognitoApi = isCognitoApi;
  }

  @Mutation
  setHasApiGateway(hasApiGateway: boolean): void {
    this.hasApiGateway = hasApiGateway;
  }
}

export const NavModule = getModule(Nav);
