import { IApiState, watchApiDetails } from "@/models";
import { ApiSchema } from "@/models/api-schema";
import { IFlow, watchFlow } from "@/models/flow";
import { getErrorMessage } from "@/utils";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";
import BreadcrumbsLayout from "./Breadcrumbs.layout";

@Component
export default class FlowManagement extends Vue {
  private flowSubscription: ZenObservable.Subscription | undefined;
  private apiSubscription: ZenObservable.Subscription | undefined;
  private flow: IFlow | null = null;
  private api: IApiState | null = null;
  private error: string | null = null;

  private get apiId() {
    return this.$route.params.apiId;
  }

  private get flowId() {
    return this.$route.params.flowId;
  }

  @Watch("flowId", { immediate: true })
  onAppIdChanged() {
    if (!this.flowId) {
      return;
    }

    this.flowSubscription?.unsubscribe();

    this.flowSubscription = watchFlow({ id: this.flowId }).subscribe({
      next: (flow) => {
        this.flow = flow;
      },
      error: (error) => {
        this.error = getErrorMessage(error);
      },
    });
  }

  @Watch("apiId", { immediate: true })
  onApiIdChanged() {
    if (!this.apiId) {
      return;
    }

    this.apiSubscription?.unsubscribe();

    this.apiSubscription = watchApiDetails({ id: this.apiId }).subscribe({
      next: (api) => {
        this.api = api;
      },
      error: (error) => {
        this.error = getErrorMessage(error);
      },
    });
  }

  private get links(): {
    name: string;
    params: Dictionary<string>;
    label: string;
  }[] {
    return [
      {
        name: "project-data",
        params: { apiId: this.api?.id ?? "" },
        label: this.api?.name ?? "",
      },
      {
        name: "project-flows",
        params: { apiId: this.api?.id ?? "" },
        label: this.$t("nav_sidebar_item_flows").toString(),
      },
      {
        name: "project-flow-editor",
        params: { apiId: this.flow?.apiId ?? "", flowId: this.flowId },
        label: this.flow?.name ?? "",
      },
    ];
  }

  private get apiSchema() {
    if (!this.api) return null;

    return new ApiSchema(this.api);
  }

  render() {
    return (
      <BreadcrumbsLayout links={this.links}>
        <router-view
          flow={this.flow}
          apiSchema={this.apiSchema}
          api={this.api}
        />
      </BreadcrumbsLayout>
    );
  }
}
