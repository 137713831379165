import { FetchPolicy } from "apollo-boost";
import {
  extractData,
  extractAndFilterItemsOrProvideDefault,
  extractPropOrThrow,
} from "./utils";
import { queryFlowRunsByFlow } from "@/api/flow-run";

export async function getFlowRunByFlowId(
  flowId: string,
  fetchPolicy?: FetchPolicy
) {
  const response = await queryFlowRunsByFlow({ id: flowId }, fetchPolicy);

  return extractAndFilterItemsOrProvideDefault(
    extractPropOrThrow(extractData(response), "listFlowRunsByFlow")
  );
}
