import { PublishingState } from "@/generated/types";
import { GraphapiModule } from "@/store/modules/graphapi";
import { Component } from "vue-property-decorator";
import ApiPublishedTypes from "../api-management/ApiPublishedTypes.mixin";

@Component
export default class UrlWidget extends ApiPublishedTypes {
  publishingRegex = /PUBLISHING/;

  $refs!: {
    graphqlUrl: HTMLElement;
  };

  get apiState() {
    return GraphapiModule.states[this.apiData?.id ?? ""] ?? this.apiData?.state;
  }

  get isUnpublished(): boolean {
    return !this.apiData?.graphqlUrl;
  }

  get unpublishedText() {
    return this.$t("url_widget_unpublished_changes_hint");
  }

  get publishingFailed(): boolean {
    return this.apiState === PublishingState.PUBLISHING_FAILED;
  }

  get isPublishing(): boolean {
    return (
      !this.publishingFailed &&
      this.apiState?.match(this.publishingRegex) !== null
    );
  }

  get graphqlUrl() {
    return this.apiData?.graphqlUrl ?? "";
  }

  copyUrl() {
    try {
      navigator.clipboard.writeText(this.$refs.graphqlUrl.innerText);
    } catch (err) {
      console.log((err as Error).message);
    }
  }

  async publishApi() {
    this.handlePublishClick();
  }

  viewPublishingProgress() {
    try {
      this.$router.push({
        name: "project-setup-publishing",
        params: { apiId: this.apiData?.id ?? "" },
      });
    } catch (err) {
      console.log(err);
    }
  }
}
