import { DedicatedApi, ICustomerListVariables } from "@/api-client";
import { IGraphqlApi } from "@/api-client/shared/types";
import { ObjectDirectiveEnum } from "@/api/common-types";
import { SortOrderType } from "@/generated/types";
import { ApiSchema } from "@/models/api-schema";
import { BaseFieldTypeEnum } from "@/models/object-type/types";
import { ApiDataModule } from "@/store/modules/api-data";
import { idFromObjectName, sortObjectArrayByKey } from "@/utils";
import { ListDataManager } from "@/utils/list-data-manager";
import _chunk from "lodash/chunk";
import _pick from "lodash/pick";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import FieldsConfiguration from "../object-view/fields-configuration";
import {
  ICellChangePayload,
  IHeaderFieldConfig,
  ITableField,
} from "../shared/table/types";
import { getIconForFieldType } from "../shared/table/utils";
import { ApiDataObjectTypeConfig } from "./ApiDataObjectTypeConfig.mixin";

export interface IItem {
  id: string;
  createdAt: string;
  updatedAt: string;
  [key: string]: any;
}

export const getInitialListVariables = (
  apiId?: string
): ICustomerListVariables => ({
  limit: 100,
  nextToken: null,
  filter: null,
  sortOrder: SortOrderType.DESC,
  sortRange: null,
  ...(apiId && { apiId }),
});

@Component
export class ApiDataObjectTypeTableData extends ApiDataObjectTypeConfig {
  @Prop() apiSchema: ApiSchema;
  @Prop() customerApi?: IGraphqlApi;
  @Prop() dedicatedApi?: DedicatedApi;

  private variables = getInitialListVariables();
  private hasFilters = false;
  private hasSortRange = false;
  protected get listDataManager() {
    return Vue.observable(
      new ListDataManager<
        { items: IItem[]; nextToken?: string | null },
        ICustomerListVariables
      >((variables) => {
        const disableGenericType = this.hasFilters || this.hasSortRange;
        const customerApi = (
          disableGenericType || this.isUserType
            ? this.dedicatedApi
            : this.customerApi
        ) as IGraphqlApi;

        return customerApi.watchListObjects<IItem>(this.objectType, variables);
      })
    );
  }

  private sortFieldsConfigByActivity(fields: ITableField[]): ITableField[] {
    return fields.sort((a, b) => {
      if (a.inactive && !b.inactive) return 1;
      if (!a.inactive && b.inactive) return -1;
      return 0;
    });
  }

  protected get fieldsConfiguration() {
    return Vue.observable(
      new FieldsConfiguration(
        this.apiSchema,
        this.objectType,
        this.customerApi?.schemaFieldTypes ?? {},
        this.apiState.enums,
        false,
        this.$t.bind(this)
      )
    );
  }

  protected get fields(): ITableField[] {
    const parentIdFields: IHeaderFieldConfig[] = [];
    const publishedFieldsSet = new Set<string>();

    this.objectSchemaFieldTypes?.fields?.forEach((field) => {
      publishedFieldsSet.add(field.name);
    }, []);

    const objectTypeFields = this.fieldsConfiguration.fields.reduce(
      (acc: IHeaderFieldConfig[], field) => {
        if (!field.isTargetConnectionField) {
          acc.push({
            name: field.name,
            type: field.type
              ? (field.type as BaseFieldTypeEnum)
              : BaseFieldTypeEnum.String,
            isReadonly: field.isReadOnly,
            isConfigurable: field.isConfigurable,
          });
        }
        return acc;
      },
      []
    );

    const firstParentId = this.objectType?.parents.first?.parentId;
    if (firstParentId) {
      const firstParent = this.apiState.types.find(
        (t) => t.id === firstParentId
      );
      if (firstParent) {
        parentIdFields.push({
          name: idFromObjectName(firstParent.name),
          type: BaseFieldTypeEnum.ID,
          isReadonly: false,
          isConfigurable: false,
        });
      }
    }

    const secondParentId = this.objectType?.parents.second?.parentId;
    if (secondParentId) {
      const secondParent = this.apiState.types.find(
        (t) => t.id === secondParentId
      );
      if (secondParent) {
        parentIdFields.push({
          name: idFromObjectName(secondParent.name),
          type: BaseFieldTypeEnum.ID,
          isReadonly: false,
          isConfigurable: false,
        });
      }
    }

    return this.sortFieldsConfigByActivity(
      sortObjectArrayByKey(objectTypeFields, "name").map((field) => ({
        key: field.name,
        label: field.name,
        type: field.type,
        iconSrc: getIconForFieldType(field.type),
        options: this.getTypeOptions(field.type),
        readOnly:
          field.isReadonly ||
          (!this.hasGenericType && !publishedFieldsSet.has(field.name)),
        inactive: !this.hasGenericType && !publishedFieldsSet.has(field.name),
        configurable: field.isConfigurable,
        sortOrder:
          field.name === this.sortByField ? this.sortDirection : undefined,
      }))
    );
  }

  private get activeFilters() {
    return ApiDataModule.activeFilters;
  }

  private get activeSortRange() {
    return ApiDataModule.activeSortRange;
  }

  private get isUserType() {
    return this.objectType.directives.includes(ObjectDirectiveEnum.USERS);
  }

  private get mutationApiClient() {
    return this.isUserType ? this.dedicatedApi : this.customerApi;
  }

  @Watch("objectTypeName")
  @Watch("customerApi")
  protected onSelectedObjectTypeChanged() {
    this.variables = getInitialListVariables();
  }

  @Watch("variables", { immediate: true })
  protected onVariablesChanged() {
    this.subscribeToObjectList(this.variables);
  }

  private subscribeToObjectList(initialVariables: ICustomerListVariables) {
    const disableGenericType = this.hasFilters || this.hasSortRange;
    const customerApi =
      disableGenericType || this.isUserType
        ? this.dedicatedApi
        : this.customerApi;

    if (!customerApi) return;

    this.listDataManager.subscribeToList(initialVariables);
  }

  protected async updateItem(payload: ICellChangePayload) {
    const { field, value, item } = payload;

    if (value === item[field]) return;

    await this.mutationApiClient?.updateObject(this.objectType, {
      input: {
        id: item.id,
        [field]: value,
        ..._pick(item, this.requiredUpdateInputParams),
      },
    });
  }

  @Watch("sortDirection")
  protected onSortOrderChange() {
    this.variables = {
      ...this.variables,
      nextToken: null,
      sortOrder: this.sortDirection,
    };
  }

  protected onReload() {
    this.listDataManager.refetch?.(this.variables);
  }

  protected async deleteData(entries: { [key: string]: string }[]) {
    if (!this.mutationApiClient) return;
    try {
      const batches = _chunk(entries, 25);

      for (const batch of batches) {
        await this.mutationApiClient.batchDeleteObjects(
          this.objectType,
          { input: batch },
          this.mutationApiClient.cacheUpdates.clearAllAfterBatchDeletionFactory(
            this.objectType,
            getInitialListVariables()
          )
        );
      }
    } catch (err) {
      this.listDataManager.errorMessage = (err as Error).message;
    }
  }

  @Watch("activeFilters")
  @Watch("activeSortRange")
  protected async onActiveFiltersChange() {
    if (!this.customerApi) return;
    const filter = this.activeFilters;
    const isIdFilter = filter && "id" in filter && filter.id !== null;
    if (isIdFilter && filter.id.eq) {
      const id = filter.id.eq;
      this.listDataManager.unsubscribe();
      this.listDataManager.isLoading = true;
      try {
        const result = await this.customerApi.queryObject(this.objectType, id);
        this.listDataManager.data = [result];
        this.listDataManager.totalRows = 1;
      } catch (err) {
        this.listDataManager.data = [];
        this.listDataManager.totalRows = 0;
      } finally {
        this.listDataManager.listVariables = getInitialListVariables();
        this.listDataManager.isLoading = false;
      }
      return;
    }
    this.hasFilters = (filter && Object.keys(filter).length > 0) || false;
    this.hasSortRange =
      (this.activeSortRange && Object.keys(this.activeSortRange).length > 0) ||
      false;

    this.variables = {
      ...getInitialListVariables(),
      filter: this.hasFilters ? filter : null,
      sortRange: this.activeSortRange,
    };
  }
}
