import {
  ACTIVITY_TYPE,
  CustomerApiForActivities,
  DedicatedApi,
  ICustomerListVariables,
} from "@/api-client";
import ApiManagementSectionHeader from "@/components/api-management/ApiManagementSectionHeader.component";
import FormTable from "@/components/forms/FormTable.component.vue";
import {
  Button,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "@/components/shared/Button.component";
import { Loader } from "@/components/shared/Loader.component";
import { SelectedDataExtractor } from "@/components/shared/table/selected-data-extractor";
import { TableEditingState } from "@/components/shared/table/table-editing-state";
import { TableSelectionState } from "@/components/shared/table/table-selection-state";
import Table from "@/components/shared/table/Table.component";
import {
  ICellDataContent,
  IItem,
  ITableField,
} from "@/components/shared/table/types";
import Tabs, { Tab } from "@/components/shared/Tabs.component";
import { SortOrderType } from "@/generated/types";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import { BContainer, BRow } from "@/lib/typed-bootstrap";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import { BaseFieldTypeEnum, IApiState, IPublishRunState } from "@/models";
import { ApiSchema } from "@/models/api-schema";
import {
  IActivityResponseMappableProps,
  getTablenameForType,
  parseActivity,
} from "@/models/customer-apis/activity";
import { getPublishRunsForApiId } from "@/models/publish-run";
import { isNotNullable } from "@/utils";
import { ListDataManager } from "@/utils/list-data-manager";
import { ProductNameEnum } from "@graphapi-io/products";
import { ApolloClient } from "apollo-boost";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

const getInitialVariables = (): ICustomerListVariables => ({
  limit: 50,
  nextToken: null,
  filter: undefined,
  sortOrder: SortOrderType.DESC,
  sortRange: undefined,
});

@Component
export default class ApiDetailsDeployments extends UserPermissions {
  @Prop({ required: true }) value: IApiState;
  @Prop({ required: true }) apiClient: ApolloClient<unknown>;
  @Prop({ required: true }) apiSchemaHandler: ApiSchemaHandler | null;

  $refs!: {
    publishRunsTable: HTMLFormElement;
  };

  private pageOptions = [25, 50];
  private publishRunsData: IPublishRunState[] = [];
  private currentTabIndex = 0;

  private selectedDataExtractor = new SelectedDataExtractor(
    [],
    [],
    true,
    false
  );

  private tableSelectionState = Vue.observable(
    new TableSelectionState(true, false)
  );

  private get tableEditingState() {
    return Vue.observable(new TableEditingState(this.listQueryActivityFields));
  }

  private get apiId(): string {
    return this.$route.params.apiId;
  }

  private get listDataManager() {
    return Vue.observable(
      new ListDataManager<
        {
          items: { [key: string]: string | number | null | undefined }[];
          nextToken?: string | null;
        },
        ICustomerListVariables
      >(
        this.customerApiForActivities.watchActivities.bind(
          this.customerApiForActivities
        )
      )
    );
  }

  private get customerApi() {
    return new DedicatedApi(
      this.apiClient,
      this.schemaFieldTypes,
      new ApiSchema(this.value, this.schemaFieldTypes)
    );
  }

  private get customerApiForActivities() {
    return new CustomerApiForActivities(this.customerApi);
  }

  private get publishRunTableFields() {
    const publishRunFields = [
      { key: "createdAt", label: this.$t("global_date").toString() },
      { key: "schemaVersion", label: this.$t("global_version").toString() },
      { key: "status", label: this.$t("global_status").toString() },
      this.canPublishApi ? { key: "publish", label: "" } : undefined,
    ].filter(isNotNullable);

    return [...(publishRunFields ?? "")];
  }

  private get activityTableFields(): ITableField[] {
    return [
      { key: "createdAt", label: this.$t("global_date").toString() },
      {
        key: "activity",
        label: this.$t("global_activity").toString(),
        formatter: (content: ICellDataContent, item?: IItem) => (
          <i18n path={content}>
            <template slot="table">
              <strong>
                {getTablenameForType(
                  (item?.recordType ?? "") as string,
                  this.value.types
                )}
              </strong>
            </template>
          </i18n>
        ),
      },
      {
        key: "recordId",
        label: this.$t("global_entry_id").toString(),
        formatter: (content: ICellDataContent, item?: IItem) => (
          <span style="font-family: monospace; font-size: 14px;">
            {item?.recordId}
          </span>
        ),
      },
    ].filter(isNotNullable);
  }

  created() {
    this.getData();
  }

  @Watch("apiSchemaHandler.inProgress", { immediate: true })
  protected onApiSchemaHandlerInitialized() {
    if (this.apiSchemaHandler && !this.apiSchemaHandler?.inProgress) {
      this.listDataManager.subscribeToList(getInitialVariables());
    }
  }

  get hasActivitiesField() {
    return this.schemaFieldTypes["Activity"] !== undefined;
  }

  activitiesListQueryFieldName() {
    return "listActivities";
  }

  get activityFields(): string[] {
    return (
      this.schemaFieldTypes[ACTIVITY_TYPE]?.fields
        ?.filter(
          (field) =>
            field.args &&
            field.args?.length === 0 &&
            field.name !== "id" &&
            field.name !== "key" &&
            field.name !== "etag" &&
            field.name !== "updatedAt"
        )
        .map((field) => field.name) ?? []
    );
  }

  get listQueryActivityFields() {
    return this.activityFields.map((field) => ({
      key: field,
      label: field,
      type: BaseFieldTypeEnum.String,
      readOnly: true,
    }));
  }

  private get schemaFieldTypes() {
    return this.apiSchemaHandler?.inProgress || !this.apiSchemaHandler
      ? {}
      : this.apiSchemaHandler?.schemaFieldTypes;
  }

  private get activityData() {
    return this.listDataManager.data.map(
      (item) =>
        parseActivity(
          item as unknown as IActivityResponseMappableProps
        ) as unknown as IItem
    );
  }

  private get products() {
    return this.value.products ?? [];
  }

  private async getData(): Promise<any> {
    try {
      this.publishRunsData = await getPublishRunsForApiId(this.apiId);
    } catch (err) {
      console.log(err);
    }
  }

  private handleRepublishClick(id: string) {
    this.$router.push({
      name: "project-republish-view",
      params: { apiId: this.apiId, versionId: id },
    });
  }

  private onTabIndexChange(index: number) {
    this.currentTabIndex = index;
  }

  render() {
    return (
      <BContainer class="api__view-setup" fluid>
        <Tabs
          class="datamodel-tabs"
          currentTabIndex={this.currentTabIndex}
          onTabIndexChange={this.onTabIndexChange}
        >
          <Tab
            title={this.$t("api_details_section_title_activities").toString()}
            id="activities"
          >
            <ApiManagementSectionHeader
              description={this.$t(
                "api_details_section_description_activities",
                {
                  timeframe: this.products.includes(ProductNameEnum.pro_bundle)
                    ? this.$t("api_details_section_activities_timeframe_pro")
                    : this.$t(
                        "api_details_section_activities_timeframe_default"
                      ),
                }
              ).toString()}
            />
            <Table
              ref="table"
              items={this.activityData}
              hasMoreItems={!!this.listDataManager.listVariables?.nextToken}
              fields={this.activityTableFields}
              withSelectionColumn
              tableSelectionState={this.tableSelectionState}
              tableEditingState={this.tableEditingState}
              selectedDataExtractor={this.selectedDataExtractor}
              onPaginationChange={this.listDataManager.onPaginationChange}
              paginationState={this.listDataManager.paginationState}
            >
              {!this.hasActivitiesField ? (
                <div>{this.$t("api_details_activites_publishing_hint")}</div>
              ) : this.listDataManager.isLoading || !this.schemaFieldTypes ? (
                <Loader data-testid="tableLoader" />
              ) : null}
            </Table>
          </Tab>
          <Tab
            title={this.$t("api_details_section_title_logs").toString()}
            id="publish-runs"
          >
            <ApiManagementSectionHeader
              description={this.$t(
                "api_details_section_description_logs"
              ).toString()}
            />
            <BRow class="default__sub-section">
              <FormTable
                value={[]}
                items={this.publishRunsData}
                fields={this.publishRunTableFields}
                pageOptions={this.pageOptions}
                key={`table-${this.publishRunsData.length}`}
                selectable={false}
                scopedSlots={{
                  "cell(publish)": (data) => (
                    <Button
                      theme={ButtonThemeEnum.secondary}
                      size={ButtonSizeEnum.xs}
                      class="mx-2"
                      onClick={() => this.handleRepublishClick(data.item.id)}
                    >
                      {this.$t("logs_republish")}
                    </Button>
                  ),
                }}
              />
            </BRow>
          </Tab>
        </Tabs>
      </BContainer>
    );
  }
}
