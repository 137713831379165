import BillingDataForm from "@/components/org-management/BillingDataForm.component.vue";
import StepProgressBar from "@/components/setup/StepProgressBar.component";
import {
  ICheckoutSession,
  ICheckoutSessionUpdateInput,
} from "@/generated/types";
import BillingAccount from "@/mixins/BillingAccount.mixin";
import CheckoutSession from "@/mixins/CheckoutSession.mixin";
import ErrorHandling from "@/mixins/ErrorHandling.mixin";
import { getCheckoutSessionForApi } from "@/models/checkout-session";
import { OrgModule } from "@/store/modules/org";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

export const mapCheckoutSessionToInput = (
  state: ICheckoutSession,
  paymentProviderId?: string
): ICheckoutSessionUpdateInput => ({
  ...state,
  orgId: OrgModule.id,
  apiId: state.apiId ?? "",
  paymentProviderId: paymentProviderId ?? "",
  sessionId: state.sessionId ?? "",
  bundleType: state.bundleType ?? "",
  cancelUrl: state.cancelUrl ?? "",
  successUrl: state.successUrl ?? "",
  description: state.description ?? "",
  lineItems: state.lineItems ?? "",
  products: state.products ?? [],
});

@Component({
  components: { BillingDataForm, StepProgressBar },
})
export default class ApiSetupBilling extends mixins(
  BillingAccount,
  CheckoutSession,
  ErrorHandling
) {
  isLoading = false;
  form = null;

  get apiId() {
    return this.$route.params.apiId;
  }

  get billingAccount() {
    return OrgModule.synth().billingAccount;
  }

  get hasValidBillingAccount() {
    return /^cus_\w+/.test(this.billingAccount?.paymentProviderId ?? "");
  }

  async onContinue(): Promise<void> {
    try {
      this.isLoading = true;
      this.errorMessage = "";
      if (!this.billingAccount?.paymentProviderId) {
        throw new Error(this.$t("error_billing_account_creation").toString());
      }

      const session = await getCheckoutSessionForApi(this.apiId);
      if (session) {
        const updatedSession = await this.upsertCheckoutSession(
          mapCheckoutSessionToInput(
            session,
            this.billingAccount?.paymentProviderId
          )
        );

        if (updatedSession?.sessionId && updatedSession?.sessionUrl) {
          if (window.Cypress) {
            this.$router.push({
              name: "project-setup-publishing",
              params: { apiId: this.apiId },
              query: { session_id: session?.sessionId },
            });
          } else {
            window.location.href = updatedSession.sessionUrl;
          }
        }
      } else {
        this.$router.push({
          name: "api-setup-bundles",
          params: { apiId: this.apiId },
        });
      }
    } catch (err) {
      this.errorMessage = (err as any).message;
    } finally {
      this.isLoading = false;
    }
  }
}
