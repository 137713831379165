import { deleteApiMutation, updateApiMutation } from "@/api/api";
import { deleteBundleSubscription } from "@/api/bundle_subscription";
import {
  ApiCreationTypeEnum,
  AuthMethodTypeEnum,
  PublishingStateEnum,
} from "@/api/common-types";
import {
  declarationSchemaForInput,
  IApiState,
  IOidcConfigState,
} from "@/models";
import { OrgModule } from "@/store";
import { formattedTimeString, isValidUrl } from "@/utils";
import { persistanceService } from "@/utils/persistance-service";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { Component, Prop, Watch } from "vue-property-decorator";

// Component imports
import FormReadonly from "@/components/forms/FormReadonly.component.vue";
import FormTable from "@/components/forms/FormTable.component.vue";
import FormInputWithLabel from "@/components/forms/inputs/FormInputWithLabel.component";
import FormSelect from "@/components/forms/inputs/FormSelect.component";
import DeleteModal from "@/components/modal/DeleteModal.component";
import CollapsibleSection from "@/components/shared/CollapsibleSection.component.vue";
import UrlWidget from "@/components/widgets/UrlWidget.component.vue";

import ProjectManagementKeysSection from "@/components/api-management/ProjectManagementKeysSection.component";
import { IBundleSubscription } from "@/generated/types";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BContainer,
  BForm,
  BRow,
  BSpinner,
} from "@/lib/typed-bootstrap";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import { getSubscriptions } from "@/models/subscriptions";
import { ApolloClient } from "apollo-boost";

@Component({
  components: {
    DeleteModal,
    FormReadonly,
    FormSelect,
    FormTable,
    CollapsibleSection,
    FormInputWithLabel,
    UrlWidget,
  },
})
export default class ProjectSettings extends UserPermissions {
  @Prop({ required: true }) value: IApiState;
  @Prop({ required: true }) apiClient: ApolloClient<unknown>;
  @Prop({ required: true }) apiSchemaHandler: ApiSchemaHandler | null;

  // Local component state
  errorMessage: string = "";
  isLoading: boolean = false;
  confirmName: string = "";
  apiName: string | null | undefined = "";
  oidcSettings: IOidcConfigState | null = null;
  selectedUserAuthMethod: AuthMethodTypeEnum | null = null;
  subscriptions: IBundleSubscription[] = [];

  // --- Computed Properties ---
  get canManageKeys() {
    return !this.isImported && this.value.graphqlUrl && this.isAuthModeApiKey;
  }

  get apiRegion() {
    return this.$t(`aws_region.${this.value.region}`);
  }

  get authModeApiKeyHeader(): string {
    return `"x-api-key: ${this.value.apiKey ?? "custom-api-key"}"`;
  }

  get authModeApiKeyLabel(): string {
    return "Api Key";
  }

  get authModeOidcLabel(): string {
    return "OpenID Connect";
  }

  get authModeCognitoLabel(): string {
    return "AWS Cognito";
  }

  get authorizationOptions() {
    const base = [
      {
        value: AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS,
        text: this.authModeCognitoLabel,
      },
    ];
    return base;
  }

  get canDeleteApis(): boolean {
    return this.canManageOrg;
  }

  get defaultAuthMode() {
    return this.selectedUserAuthMethod ?? AuthMethodTypeEnum.AWS_API_KEY;
  }

  get additionalAuthModes() {
    return this.defaultAuthMode !== AuthMethodTypeEnum.AWS_API_KEY &&
      this.value.creationType !== ApiCreationTypeEnum.IMPORT
      ? [AuthMethodTypeEnum.AWS_API_KEY]
      : [];
  }

  get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  get deleteTitle(): string {
    return this.$t("warning_delete_api_title", {
      name: this.value.name,
    }).toString();
  }

  get deleteInfo(): string {
    return this.$t("warning_delete_api_info", {
      name: this.value.name,
    }).toString();
  }

  get deleteInstruction(): string {
    return this.$t("warning_delete_api_instruction").toString();
  }

  get hasAdditionalAuthModes() {
    return (this.value.additionalAuthModes ?? []).length > 0;
  }

  get isAuthModeApiKey(): boolean {
    return (
      this.value.defaultAuthMode === AuthMethodTypeEnum.AWS_API_KEY ||
      (this.value.additionalAuthModes
        ? this.value.additionalAuthModes.some(
            (auth) => auth === AuthMethodTypeEnum.AWS_API_KEY
          )
        : false)
    );
  }

  get isAuthModeCognito(): boolean {
    return (
      this.selectedUserAuthMethod === AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS
    );
  }

  get showUserAuthenticationSection() {
    return !this.isPublished || this.isAuthModeCognito || this.isAuthModeOidc;
  }

  get isAuthModeOidc(): boolean {
    return this.selectedUserAuthMethod === AuthMethodTypeEnum.AWS_OIDC;
  }

  get isImported(): boolean {
    return this.value.creationType === ApiCreationTypeEnum.IMPORT;
  }

  get isPublished(): boolean {
    return (
      this.value.state !== PublishingStateEnum.INITIALIZED && !this.isImported
    );
  }

  get oidcClientId(): string {
    return this.oidcSettings?.clientId ?? "";
  }
  set oidcClientId(clientId: string) {
    this.oidcSettings = {
      clientId,
      issuerUrl: this.oidcSettings?.issuerUrl ?? "",
    };
  }

  get oidcIssuerUrl(): string {
    return this.oidcSettings?.issuerUrl ?? "";
  }
  set oidcIssuerUrl(issuerUrl: string) {
    this.oidcSettings = {
      clientId: this.oidcSettings?.clientId,
      issuerUrl,
    };
  }

  get realtimeURL() {
    if (this.value.graphqlUrl) {
      const uri = new URL(this.value.graphqlUrl ?? "");
      const hostnameParts = uri.hostname.split(".");
      hostnameParts[1] = "appsync-realtime-api";
      return `wss://${hostnameParts.join(".")}${uri.pathname}`;
    }
    return "";
  }

  get schema(): string {
    return declarationSchemaForInput({
      ...this.value,
      name: this.apiName,
      defaultAuthMode: this.defaultAuthMode,
      additionalAuthModes: this.additionalAuthModes,
      ...(this.isAuthModeOidc &&
        this.oidcSettings && { oidc: this.oidcSettings }),
    });
  }

  get showNameCancelSaveButtons(): boolean {
    return this.apiName !== this.value.name;
  }

  get showAuthCancelSaveButtons(): boolean {
    return (
      this.value.additionalAuthModes?.length !==
        this.additionalAuthModes.length ||
      (this.additionalAuthModes.length > 0 &&
        this.selectedUserAuthMethod !== this.value.defaultAuthMode) ||
      (this.isAuthModeOidc && !_isEqual(this.value.oidc, this.oidcSettings))
    );
  }

  get subscription() {
    const bundle = this.subscriptions[0]?.products?.find((p) =>
      p?.match(/.*_bundle/g)
    );
    return (bundle ?? "starter_bundle").replace(/_bundle/g, "").toUpperCase();
  }

  // --- Lifecycle & Watchers ---

  async created() {
    await this.initialize();
  }

  @Watch("value", { immediate: true, deep: true })
  async initialize() {
    this.apiName = this.value.name;
    this.selectedUserAuthMethod =
      this.value.defaultAuthMode !== AuthMethodTypeEnum.AWS_API_KEY
        ? this.value.defaultAuthMode ?? null
        : null;

    if (this.isAuthModeOidc) {
      this.oidcSettings = {
        issuerUrl: this.value.oidc?.issuerUrl ?? "",
        clientId: this.value.oidc?.clientId,
      };
    }

    if (this.value.id) {
      this.subscriptions = await getSubscriptions(this.value.id);
    }
  }

  // --- Methods ---

  async onDelete(): Promise<void> {
    try {
      if (!this.canManageOrg) {
        throw new Error(this.$t("error_api_deletion_requires_role").toString());
      }

      if (this.value.id) {
        this.subscriptions = await getSubscriptions(this.value.id);
      }

      if (this.subscriptions.length > 0) {
        await Promise.all([
          deleteApiMutation(OrgModule.id, { input: { id: this.value.id } }),
          ...this.subscriptions.map(({ id, paymentProviderId }) =>
            deleteBundleSubscription({
              input: {
                id,
                paymentProviderId: paymentProviderId ?? "",
              },
            })
          ),
        ]);
      } else {
        await deleteApiMutation(OrgModule.id, {
          input: { id: this.value.id },
        });
      }
      persistanceService.removeActiveRouteKey();
      this.$router.push({
        name: "org-api-dashboard",
        params: { orgId: OrgModule.id },
      });
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }
  }

  onCancelNameChange() {
    this.apiName = this.value.name;
  }

  onCancelAuthModeChange() {
    this.selectedUserAuthMethod = this.value.defaultAuthMode ?? null;
  }

  async onSaveSettingsChange() {
    try {
      if (this.isAuthModeOidc) {
        if (!isValidUrl(this.oidcSettings?.issuerUrl ?? "")) {
          throw new Error(
            this.$t("error_api_create_requires_issuerurl").toString()
          );
        }

        if (!this.oidcSettings?.clientId) {
          throw new Error(
            this.$t("error_api_create_requires_clientid").toString()
          );
        }
      }

      this.isLoading = true;
      this.errorMessage = "";
      const response = await updateApiMutation({
        id: this.value.id,
        name: this.apiName,
        defaultAuthMode: this.defaultAuthMode,
        additionalAuthModes: this.additionalAuthModes,
        oidc: this.isAuthModeOidc ? JSON.stringify(this.oidcSettings) : null,
        schema: this.schema,
      });
      this.value.name = _get(response, "data.updateApi.name");
      this.value.defaultAuthMode = _get(
        response,
        "data.updateApi.defaultAuthMode"
      );
      this.value.additionalAuthModes = _get(
        response,
        "data.updateApi.additionalAuthModes"
      );
      const oidcJsonString = _get(response, "data.updateApi.oidc");
      this.value.oidc = oidcJsonString ? JSON.parse(oidcJsonString) : null;
      this.value.schema = _get(response, "data.updateApi.schema");
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isLoading = false;
    }
  }

  formattedTimeString(expiresAtDate: string): string {
    return formattedTimeString(expiresAtDate);
  }

  // A dummy submit handler for the form
  onSubmit(e: Event) {
    e.preventDefault();
    // (Optional) trigger onSaveSettingsChange() or simply no-op.
  }

  // --- Render Function (TSX Template) ---

  render() {
    return (
      <BContainer class="api__view-setup">
        <UrlWidget />
        <BAlert
          show={this.showErrorMessage}
          variant="danger"
          data-testid="apiSettingsFormError"
        >
          {this.errorMessage}
        </BAlert>

        <BRow class="default__section">
          <div
            class="api-details__section-header"
            data-testid="apiSettingsTitle"
          >
            {this.$t("api_settings_title_settings").toString()}
          </div>
        </BRow>

        <BRow class="default__section">
          <BForm
            ref="form"
            class="default__inline-form"
            acceptCharset="UTF-8"
            role="form"
            novalidate
            onSubmit={(e: Event) => {
              e.stopPropagation();
              e.preventDefault();
              this.onSubmit(e);
            }}
          >
            <BRow cols="1" colsMd="2">
              <BCol>
                <FormReadonly
                  id="apiId"
                  value=""
                  label={this.value.id}
                  upperCaseLabel={false}
                  labelAmendment="Project ID"
                />
              </BCol>
              <BCol>
                <FormReadonly
                  id="subscription"
                  value=""
                  label={this.subscription}
                  upperCaseLabel={false}
                  labelAmendment="Subscription plan"
                />
              </BCol>
            </BRow>

            <BRow cols="1" colsMd="2">
              <BCol>
                <FormInputWithLabel
                  id="apiName"
                  value={this.apiName}
                  onInput={(newVal) => {
                    this.apiName = newVal as string;
                  }}
                  label={this.$t("form_input_label_api_name").toString()}
                  placeholderText={this.$t(
                    "form_placeholder_api_name"
                  ).toString()}
                />
              </BCol>
              <BCol>
                <FormInputWithLabel
                  id="apiRegion"
                  value={this.apiRegion}
                  onInput={() => {}}
                  label={this.$t("form_input_label_api_region").toString()}
                  placeholderText={this.$t(
                    "form_placeholder_api_region"
                  ).toString()}
                  readonly={true}
                />
              </BCol>
              <BCol>
                <FormInputWithLabel
                  id="graphqlUrl"
                  value={this.value.graphqlUrl}
                  onInput={() => {}}
                  label={this.$t("form_input_label_api_graphql_url").toString()}
                  placeholderText={this.$t(
                    "form_placeholder_api_graphql_url"
                  ).toString()}
                  readonly={true}
                />
              </BCol>
              <BCol>
                <FormInputWithLabel
                  id="graphqlRealtimeUrl"
                  value={this.realtimeURL}
                  onInput={() => {}}
                  label={this.$t(
                    "form_input_label_api_graphql_realtime_url"
                  ).toString()}
                  placeholderText={this.$t(
                    "form_placeholder_api_graphql_realtime_url"
                  ).toString()}
                  readonly={true}
                />
              </BCol>
            </BRow>

            {this.showNameCancelSaveButtons && (
              <BRow class="buttons__section">
                <button
                  class="action-button__pill--outline action-button__flex-props"
                  onClick={(e: Event) => {
                    e.preventDefault();
                    this.onCancelNameChange();
                  }}
                  type="reset"
                >
                  {this.$t("global_cta_cancel").toString()}
                </button>
                <button
                  class="action-button__pill--gradient action-button__flex-props"
                  onClick={(e: Event) => {
                    e.preventDefault();
                    this.onSaveSettingsChange();
                  }}
                  type="submit"
                >
                  {this.isLoading && (
                    <div class="default__spinner--small" role="status">
                      <BSpinner
                        class="ml-auto"
                        type="grow"
                        label="Saving..."
                        small
                        show={false}
                      />
                    </div>
                  )}
                  {this.$t("global_cta_save").toString()}
                </button>
              </BRow>
            )}
          </BForm>
        </BRow>

        <BRow class="default__section">
          <div class="api-details__section-header">
            {this.$t("api_settings_title_auth").toString()}
          </div>
        </BRow>

        <BRow class="default__section">
          <BForm
            ref="form"
            class="default__inline-form"
            acceptCharset="UTF-8"
            role="form"
            novalidate
            data-testid="authSettingsForm"
          >
            {this.showUserAuthenticationSection && (
              <BRow cols="1" colsMd="2">
                <BCol>
                  <FormSelect
                    id="user-auth-method"
                    value={this.selectedUserAuthMethod}
                    onInput={(val: AuthMethodTypeEnum) =>
                      (this.selectedUserAuthMethod = val)
                    }
                    label={this.$t("form_input_label_api_auth_user").toString()}
                    placeholderText={this.$t(
                      "api_setup_publish_auth_select_placeholder"
                    ).toString()}
                    selectOptions={this.authorizationOptions}
                    isReadonly={this.isPublished}
                    data-testid="formSelectUserAuthMode"
                  />
                </BCol>
                {this.isAuthModeOidc && (
                  <BCol>
                    <FormInputWithLabel
                      id="oidcIssuerUrl"
                      value={this.oidcIssuerUrl}
                      onInput={(newVal) =>
                        (this.oidcIssuerUrl = newVal as string)
                      }
                      label={this.$t(
                        "form_input_label_api_issuer_url"
                      ).toString()}
                      placeholderText={this.$t(
                        "form_placeholder_issuer_url"
                      ).toString()}
                      inputType="url"
                    />
                  </BCol>
                )}
                {this.isAuthModeOidc && (
                  <BCol>
                    <FormInputWithLabel
                      id="oidcClientId"
                      value={this.oidcClientId}
                      onInput={(newVal) =>
                        (this.oidcClientId = newVal as string)
                      }
                      label={this.$t(
                        "form_input_label_api_client_id"
                      ).toString()}
                      placeholderText={this.$t(
                        "form_placeholder_client_id"
                      ).toString()}
                      inputType="text"
                    />
                  </BCol>
                )}
                {this.isAuthModeCognito && (
                  <BCol>
                    <FormInputWithLabel
                      id="cognitoClientId"
                      value={this.value.authProviderClientId}
                      label={this.$t(
                        "form_input_label_api_client_id"
                      ).toString()}
                      placeholderText={this.$t(
                        "form_placeholder_api_client_id"
                      ).toString()}
                      readonly={true}
                    />
                  </BCol>
                )}
                {this.isAuthModeCognito && (
                  <BCol>
                    <FormInputWithLabel
                      id="cognitoPoolId"
                      value={this.value.authProviderPoolId}
                      label={this.$t("form_input_label_api_pool_id").toString()}
                      placeholderText={this.$t(
                        "form_placeholder_api_pool_id"
                      ).toString()}
                      readonly={true}
                    />
                  </BCol>
                )}
                {this.isAuthModeCognito && (
                  <BCol>
                    <FormInputWithLabel
                      id="cognitoProviderUrl"
                      value={this.value.authProviderUrl}
                      label={this.$t(
                        "form_input_label_api_pool_url"
                      ).toString()}
                      placeholderText={this.$t(
                        "form_placeholder_api_pool_url"
                      ).toString()}
                      readonly={true}
                    />
                  </BCol>
                )}
              </BRow>
            )}
            {this.showUserAuthenticationSection && (
              <BRow>
                <BCol>
                  <div class="section__separator"></div>
                </BCol>
              </BRow>
            )}
            {this.isAuthModeApiKey && (
              <BRow cols="1" colsMd="2">
                <BCol>
                  <FormInputWithLabel
                    id="authModeApiKey"
                    value={this.authModeApiKeyLabel}
                    readonly={true}
                    label={this.$t(
                      "form_input_label_api_basic_auth"
                    ).toString()}
                    placeholderText={this.$t(
                      "form_placeholder_api_key_auth"
                    ).toString()}
                  />
                  <div class="default__form-hint">
                    <i18n path="api_key_usage_hint">
                      {() => (
                        <span class="highlight">
                          {this.authModeApiKeyHeader}
                        </span>
                      )}
                    </i18n>
                  </div>
                </BCol>
                <BCol>
                  <FormInputWithLabel
                    id="apiKey"
                    value={this.value.apiKey}
                    onInput={() => {}}
                    label={this.$t(
                      "form_input_label_api_defaultkey"
                    ).toString()}
                    placeholderText={this.$t(
                      "form_placeholder_api_defaultkey"
                    ).toString()}
                    readonly={true}
                  />
                </BCol>
              </BRow>
            )}
            {this.showAuthCancelSaveButtons && (
              <BRow class="buttons__section">
                <button
                  class="action-button__pill--outline action-button__flex-props"
                  onClick={(e: Event) => {
                    e.preventDefault();
                    this.onCancelAuthModeChange();
                  }}
                  type="reset"
                  data-testid="authModeCancelButton"
                >
                  {this.$t("global_cta_cancel").toString()}
                </button>
                <button
                  class="action-button__pill--gradient action-button__flex-props"
                  onClick={(e: Event) => {
                    e.preventDefault();
                    this.onSaveSettingsChange();
                  }}
                  type="submit"
                  data-testid="authModeSaveButton"
                >
                  {this.isLoading && (
                    <div class="default__spinner--small" role="status">
                      <BSpinner
                        class="ml-auto"
                        type="grow"
                        label="Saving..."
                        small
                        show={false}
                      />
                    </div>
                  )}
                  {this.$t("global_cta_save").toString()}
                </button>
              </BRow>
            )}
          </BForm>
        </BRow>

        {this.canManageKeys && (
          <CollapsibleSection id="apiKeysSection" class="mt-3">
            {({ isOpen }: { isOpen: boolean }) => (
              <ProjectManagementKeysSection
                apiSchemaHandler={this.apiSchemaHandler}
                value={this.value}
                apiClient={this.apiClient}
                isVisible={isOpen}
                apiId={this.value.id}
              />
            )}
          </CollapsibleSection>
        )}

        {this.canDeleteApis && (
          <div class="default__section">
            <BRow class="default__section">
              <div class="default__section-header danger__zone">
                {this.$t("dangerzone").toString()}
              </div>
            </BRow>
            <BRow class="default__section">
              <BCard
                tag="article"
                body-class="danger__zone-banner-body"
                class="danger__zone-banner"
              >
                <BRow>
                  <BCol md="8">
                    <div class="danger__zone-headline">
                      {this.$t("dangerzone_api_hint").toString()}
                    </div>
                    <div class="danger__zone-subline">
                      <i18n path="dangerzone_support">
                        {() => (
                          <a
                            role="button"
                            class="get-started__button cta__align-elastic"
                            href="mailto:hello@graphapi.com"
                          >
                            {this.$t("global_contact_us").toString()}
                          </a>
                        )}
                      </i18n>
                    </div>
                  </BCol>
                  <BCol md="4">
                    <BButton
                      id="dangerZoneDeleteButton"
                      class="danger__zone-delete-button"
                      data-testid="delete-api-button"
                      onClick={() => {
                        this.$bvModal.show("delete-modal");
                      }}
                    >
                      {this.$t("global_cta_delete_api").toString()}
                    </BButton>
                    <DeleteModal
                      onUpdate={(val: string) => (this.confirmName = val)}
                      onConfirmDelete={this.onDelete}
                      name={this.value.name ?? ""}
                      title={this.deleteTitle}
                      info={this.deleteInfo}
                      instruction={this.deleteInstruction}
                    />
                  </BCol>
                </BRow>
              </BCard>
            </BRow>
          </div>
        )}
      </BContainer>
    );
  }
}
