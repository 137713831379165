import { LanguageCodeEnum, ObjectDirectiveEnum } from "@/api/common-types";
import ProjectSetupSchemaTemplateCard from "@/components/project-setup/ProjectSetupSchemaTemplateCard.component";
import {
  Button,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "@/components/shared/Button.component";
import { BAlert, BCardGroup, BForm } from "@/lib/typed-bootstrap";
import ErrorHandling from "@/mixins/ErrorHandling.mixin";
import {
  declarationSchemaForInput,
  IApiState,
  IEnumState,
  IObjectTypeState,
  updateApi,
} from "@/models";
import { getSchemaTemplates, ISchemaTemplate } from "@/models/schema-template";
import { getErrorMessage } from "@/utils";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import Modal from "../shared/Modal.component";
import {
  default as style,
  default as styles,
} from "./ProjectSetupModelTemplates.component.module.scss";

import { SUBSCRIBE_SCHEMA_PROMPT } from "@/api/schema-prompt";
import aiIconBlack from "@/assets/img/icon-ai-black.svg";
import aiIconWhite from "@/assets/img/icon-ai-white.svg";
import { SubscriptionHandler } from "@/lib/subscription-handler";
import { createSchemaPrompt, getSchemaPrompt } from "@/models/schema-prompt";
import { GraphapiModule, UserModule } from "@/store";
import { UIModule } from "@/store/modules/ui";
import Input, { InputSizeEnum } from "../shared/Input.component";
import { Loader } from "../shared/Loader.component";
import { ThemedImage } from "../shared/ThemedImage.component";

const TEMPLATES_MODAL_ID = "templates-modal";

const authDirectives: ObjectDirectiveEnum[] = [
  ObjectDirectiveEnum.PUBLIC_CREATE,
  ObjectDirectiveEnum.PUBLIC_READ,
  ObjectDirectiveEnum.PUBLIC_UPDATE,
  ObjectDirectiveEnum.PUBLIC_DELETE,
  ObjectDirectiveEnum.GROUP,
];

const aiIcon = {
  light: aiIconWhite,
  dark: aiIconBlack,
};

@Component
export default class ProjectSetupModelTemplates extends ErrorHandling {
  _tsx!: tsx.DeclareProps<{
    visible: boolean;
    apiData: IApiState;
  }> &
    tsx.DeclareOnEvents<{
      onHide: boolean;
    }>;
  @Prop({ required: true }) visible: boolean;
  @Prop({ required: true }) apiData: IApiState;
  @Emit("hide") onHide(_success: boolean) {}

  private templates: ISchemaTemplate[] = [];
  private selectedTemplateId: string | null = null;
  private isLoading = false;
  private isGeneratingThroughAi = false;
  private aiPrompt = "";

  private handleAiPromptChange(value: string) {
    this.errorMessage = "";
    this.aiPrompt = value;
  }

  private waitForNextSchemaPromptUpdate(id: string) {
    return new Promise<void>((resolve, reject) => {
      const subscription = new SubscriptionHandler({
        host: process.env.VUE_APP_GRAPHQL_REALTIME_HOST ?? "",
        accessToken: UserModule.accessToken,
        query: JSON.stringify({
          query: SUBSCRIBE_SCHEMA_PROMPT,
          variables: {
            id,
          },
        }),
        realtimeEndpoint: process.env.VUE_APP_GRAPHQL_REALTIME_ENDPOINT ?? "",
        onMessageHandler: () => {
          subscription.stopListening();
          resolve();
        },
        onErrorHandler: (err) => {
          reject(err);
        },
      });
    });
  }

  private async generateSchemaFromAiPrompt(event: Event) {
    event.preventDefault();
    try {
      this.isGeneratingThroughAi = true;
      const { id } = await createSchemaPrompt({
        prompt: this.aiPrompt,
        userEmail: UserModule.email,
        apiId: this.apiData.id,
      });
      await this.waitForNextSchemaPromptUpdate(id);
      const { schema } = await getSchemaPrompt(id);
      if (!schema || schema.objectTypes.length === 0) {
        throw new Error(this.$t("api_setup_templates_ai_error").toString());
      }
      await this.updateSchema(
        schema?.objectTypes ?? [],
        schema?.enumTypes ?? []
      );
    } catch (err) {
      this.errorMessage = getErrorMessage(err);
    } finally {
      this.isGeneratingThroughAi = false;
    }
  }

  private get selectedTemplate() {
    return this.templates.find(
      (template) => template.id === this.selectedTemplateId
    );
  }

  private get lang() {
    switch (this.$i18n.locale) {
      case "de":
        return LanguageCodeEnum.DE;
      case "en":
      default:
        return LanguageCodeEnum.EN;
    }
  }

  private handleModalHide() {
    this.onHide(false);
    ("");
  }

  @Watch("lang", { immediate: true })
  async onLangChange() {
    this.templates = await getSchemaTemplates(this.lang);
  }

  private async onSubmit(ev: Event) {
    ev.preventDefault();

    await this.updateSchema(
      this.omitAuthDirectives(this.selectedTemplate?.schema.objectTypes ?? []),
      this.selectedTemplate?.schema.enumTypes ?? []
    );
  }

  private async updateSchema(types: IObjectTypeState[], enums: IEnumState[]) {
    try {
      this.isLoading = true;
      const apiState: IApiState = {
        ...this.apiData,
        types,
        enums,
      };

      const schema = declarationSchemaForInput(apiState);
      await updateApi({
        ...apiState,
        schema,
      });
      this.onHide(true);
      GraphapiModule.reloadApi(apiState.id);
    } catch (err) {
      console.error(err);
      this.errorMessage = getErrorMessage(err);
    } finally {
      this.isLoading = false;
    }
  }

  private handleSelect(templateId: string) {
    this.selectedTemplateId = templateId;
  }

  private omitAuthDirectives(
    objectTypes: IObjectTypeState[]
  ): IObjectTypeState[] {
    return objectTypes?.map((objectType) => {
      const filteredDirectives = objectType.directives.filter(
        (directive) =>
          !authDirectives.includes(directive as ObjectDirectiveEnum)
      );

      return {
        ...objectType,
        directives: filteredDirectives,
      };
    });
  }

  render() {
    return (
      <Modal
        id={TEMPLATES_MODAL_ID}
        visible={this.visible}
        onHide={this.handleModalHide}
        dialogClass={style.modal}
        scopedSlots={{
          body: ({ cancel }) => [
            <BForm
              class={style.promptContainer}
              onSubmit={this.generateSchemaFromAiPrompt}
            >
              <Input
                value={this.aiPrompt}
                onInput={this.handleAiPromptChange}
                placeholder={this.$t(
                  "api_setup_templates_ai_placeholder"
                ).toString()}
                maxlength={256}
                disabled={this.isGeneratingThroughAi || this.isLoading}
                inputSize={InputSizeEnum.lg}
              />
              <Button
                class={styles.button}
                size={ButtonSizeEnum.md}
                theme={ButtonThemeEnum.primary}
                disabled={
                  this.isGeneratingThroughAi || this.isLoading || !this.aiPrompt
                }
                type="submit"
              >
                {this.isGeneratingThroughAi ? (
                  <Loader
                    small
                    variant={UIModule.isLightTheme ? "light" : "dark"}
                  />
                ) : (
                  [
                    <ThemedImage src={aiIcon} class={style.aiIcon} />,
                    this.$t("api_setup_templates_ai_button"),
                  ]
                )}
              </Button>
            </BForm>,
            <BForm onSubmit={this.onSubmit}>
              <BAlert
                show={this.showErrorMessage}
                variant="danger"
                data-testid="apiSetupFormError"
              >
                {this.errorMessage}
              </BAlert>
              <div class={style.templatesHeader}>
                {this.$t("api_setup_templates_headline")}
              </div>
              <BCardGroup deck class={style.templates}>
                {this.templates.map((template) => (
                  <ProjectSetupSchemaTemplateCard
                    key={template.id}
                    template={template}
                    isSelected={template.id === this.selectedTemplateId}
                    onToggleSelect={this.handleSelect}
                  />
                ))}
              </BCardGroup>
              <Modal.Divider />
              <Modal.ActionButtons
                class={[
                  styles.actionButtons,
                  { [styles.withErrors]: this.showErrorMessage },
                ]}
              >
                <Button
                  onClick={cancel}
                  class={styles.button}
                  theme={ButtonThemeEnum.naked}
                  type="button"
                  data-testid="skipTemplatesButton"
                  disabled={this.isLoading || this.isGeneratingThroughAi}
                >
                  {this.$t("api_setup_templates_skip")}
                </Button>
                <Button
                  theme={ButtonThemeEnum.primary}
                  class={styles.button}
                  data-testid="useTemplateButton"
                  type="submit"
                  disabled={this.showErrorMessage || !this.selectedTemplate}
                >
                  {this.isLoading ? (
                    <Loader />
                  ) : (
                    this.$t("api_setup_templates_use")
                  )}
                </Button>
              </Modal.ActionButtons>
            </BForm>,
          ],
        }}
      ></Modal>
    );
  }
}
