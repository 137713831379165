import { createBundleSubscriptionMutation } from "@/api/bundle_subscription";
import { PublishingStateEnum } from "@/api/common-types";
import { IProgressStepState } from "@/components/setup/ProgressStep.component";
import TextSection from "@/components/shared/TextSection.component";
import { IBundleSubscriptionInput } from "@/generated/types";
import { getApiDetails, IApiState } from "@/models";
import { ApiRegionEnum, publishApi } from "@/models/api";
import {
  getCheckoutSessionsByApiForSessionId,
  ICheckoutSessionState,
} from "@/models/checkout-session";
import {
  mapApiRegionToAWSRegionEnum,
  obtainAwsAccountId,
} from "@/models/coupon";
import { createSubscriptionItemsFromIds } from "@/models/subscription-item";
import { GraphapiModule } from "@/store/modules/graphapi";
import { ProductNameEnum } from "@graphapi-io/products";
import { Component, Vue, Watch } from "vue-property-decorator";

// Child components
import StepProgressBar from "@/components/setup/StepProgressBar.component";
import { OrgModule } from "@/store";
import { isNotNullable } from "@/utils";
import { persistanceService } from "@/utils/persistance-service";

const createBundleSubscriptionAndSubscriptionItems = async (
  input: IBundleSubscriptionInput
) => {
  const { data } = await createBundleSubscriptionMutation(input);

  const paymentProviderSubscriptionItemIds =
    data?.createBundleSubscription?.paymentProviderSubscriptionItemIds;
  const id = data?.createBundleSubscription?.id;

  if (id && paymentProviderSubscriptionItemIds) {
    await createSubscriptionItemsFromIds(
      paymentProviderSubscriptionItemIds.filter(isNotNullable),
      id
    );
  }
};

@Component({
  components: { StepProgressBar, TextSection },
})
export default class ApiSetupPublishing extends Vue {
  apiData: IApiState = {} as IApiState;

  publishingProgressOrder = [
    PublishingStateEnum.PUBLISHING,
    PublishingStateEnum.PUBLISHING_PREPARE,
    PublishingStateEnum.PUBLISHING_SCHEMA,
    PublishingStateEnum.PUBLISHING_RESOLVERS,
    PublishingStateEnum.PUBLISHED,
  ];

  publishingStageStarted: PublishingStateEnum[] = [];

  get steps(): IProgressStepState[] {
    return [
      {
        label: "Prepare Stack",
        labelKey: "api_publish_steps_1",
        inProgress: this.isPublishingPrep,
        done: this.isDonePublishingPrep,
        failed: this.publishingFailed,
      },
      {
        label: "Create Schema",
        labelKey: "api_publish_steps_2",
        inProgress: this.isPublishingSchema,
        done: this.isDonePublishingSchema,
        failed: this.publishingSchemaFailed,
      },
      {
        label: "Deploy Resolvers",
        labelKey: "api_publish_steps_3",
        inProgress: this.isPublishingResolvers,
        done: this.isDonePublishingResolvers,
        failed: this.publishingResolversFailed,
      },
    ];
  }

  get currentApiState(): PublishingStateEnum {
    return GraphapiModule.states[this.apiData.id] as PublishingStateEnum;
  }

  get prevApiState(): PublishingStateEnum {
    return this.publishingStageStarted[this.publishingStageStarted.length - 1];
  }

  get publishingStep(): number {
    return this.publishingProgressOrder.indexOf(this.currentApiState);
  }

  get publishingFailed(): boolean {
    return this.currentApiState === "PUBLISHING_FAILED";
  }

  get isPublishingPrep(): boolean {
    return (
      this.publishingStep === 0 ||
      this.publishingStep === 1 ||
      this.publishingProgressOrder.indexOf(this.prevApiState) === 0 ||
      this.publishingProgressOrder.indexOf(this.prevApiState) === 1
    );
  }

  get isDonePublishingPrep(): boolean {
    return (
      this.publishingStep > 1 ||
      this.publishingProgressOrder.indexOf(this.prevApiState) > 1
    );
  }

  get publishingPrepFailed(): boolean {
    return this.publishingFailed && this.isPublishingPrep;
  }

  get isPublishingSchema(): boolean {
    return (
      this.publishingStep === 2 ||
      this.publishingProgressOrder.indexOf(this.prevApiState) === 2
    );
  }

  get isDonePublishingSchema(): boolean {
    return (
      this.publishingStep > 2 ||
      this.publishingProgressOrder.indexOf(this.prevApiState) > 2
    );
  }

  get publishingSchemaFailed(): boolean {
    return this.publishingFailed && this.isPublishingSchema;
  }

  get isPublishingResolvers(): boolean {
    return (
      this.publishingStep === 3 ||
      this.publishingProgressOrder.indexOf(this.prevApiState) === 3
    );
  }

  get isDonePublishingResolvers(): boolean {
    return (
      this.publishingStep > 3 ||
      this.publishingProgressOrder.indexOf(this.prevApiState) > 3
    );
  }

  get publishingResolversFailed(): boolean {
    return this.publishingFailed && this.isPublishingResolvers;
  }

  async obtainAwsAccountId() {
    if (this.apiData.awsAccountId) {
      return this.apiData.awsAccountId;
    }

    const validAwsAccountID = await obtainAwsAccountId(
      mapApiRegionToAWSRegionEnum(
        (this.apiData.region as ApiRegionEnum) ?? ApiRegionEnum.EuropeFrankfurt
      ),
      this.apiData.id
    );

    return validAwsAccountID;
  }

  @Watch("currentApiState")
  onStateChange() {
    if (!this.publishingFailed) {
      this.publishingStageStarted.push(this.currentApiState);
    }
  }

  async created() {
    try {
      this.apiData = await getApiDetails({ id: this.$route.params.apiId });
      GraphapiModule.initializeStateSubscription({
        apiId: this.apiData.id,
        state: this.apiData.state,
      });
      if (!this.publishingProgressOrder.includes(this.apiData.state)) {
        if (!Array.isArray(this.$route.query?.session_id)) {
          const sessionId = this.$route.query?.session_id;
          const checkoutSessions = await getCheckoutSessionsByApiForSessionId(
            this.apiData.id,
            sessionId
          );
          const session = checkoutSessions[0] as ICheckoutSessionState;
          if (session) {
            await createBundleSubscriptionAndSubscriptionItems({
              paymentProviderId: "",
              paymentProviderCustomerId: "",
              lineItems: session.lineItems,
              products: session.products?.filter(isNotNullable) ?? [],
              apiId: this.apiData.id,
              orgId: this.apiData.orgId ?? OrgModule.id,
              paymentProviderCheckoutSessionId: session.sessionId,
            });

            if (session.metadata && JSON.parse(session.metadata).length > 0) {
              await createBundleSubscriptionAndSubscriptionItems({
                paymentProviderId: "",
                paymentProviderCustomerId: "",
                lineItems: session.metadata,
                products: session.products?.filter(isNotNullable) ?? [],
                apiId: this.apiData.id,
                orgId: this.apiData.orgId ?? OrgModule.id,
                paymentProviderCheckoutSessionId: "",
              });
            }

            if (!window.Cypress) {
              this.apiData.awsAccountId = await this.obtainAwsAccountId();
              if (!this.apiData.awsAccountId) {
                throw new Error(
                  this.$t("error_api_create_requires_awsaccount").toString()
                );
              }
              await publishApi(
                this.apiData,
                this.apiData.state,
                session.products as ProductNameEnum[]
              );
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
      persistanceService.removeApiData();
    }
  }

  onClose() {
    this.$router.push({
      name: "project-overview",
      params: { apiId: this.apiData.id },
    });
  }
}
