import { BAlert, BForm, BModal, BSpinner } from "@/lib/typed-bootstrap";
import { withNumberInput } from "@/utils";
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import FormInputWithLabel from "../forms/inputs/FormInputWithLabel.component";
import Modal from "../shared/Modal.component";
import styles from "./BaseFormModal.component.module.scss";

export enum FormModalEditingType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

export enum FormModalInputTypeEnum {
  INT = "Int",
  FLOAT = "Float",
  STRING = "String",
}

export interface IFormModalInputState {
  type: FormModalInputTypeEnum;
  label: string;
  placeholderText: string;
  value: any;
  key: string;
  min?: number;
  max?: number;
}

@Component({
  components: {
    FormInputWithLabel,
    ModalButtonsSection: Modal.ActionButtons,
  },
})
export default class BaseFormModal extends Vue {
  _tsx!: tsx.DeclareProps<{
    editMode?: BaseFormModal["editMode"];
    title: BaseFormModal["title"];
    formData: BaseFormModal["formData"];
  }> &
    tsx.DeclareOnEvents<{
      onHidden: void;
      onCreate: string;
      onUpdate: string;
    }>;

  @Prop({ required: false, default: FormModalEditingType.CREATE })
  editMode: FormModalEditingType;
  @Prop({ required: true }) title: string;
  @Prop({ required: true }) formData: IFormModalInputState[];

  @Emit("onCreate") onCreate() {}
  @Emit("onUpdate") onUpdate() {}

  errorMessage: string = "";
  isLoading: boolean = false;
  modalId: string = "form-modal";

  get editingModeButtonLabel() {
    return this.editMode === FormModalEditingType.UPDATE
      ? this.$t("global_cta_save")
      : this.$t("global_cta_create");
  }

  get formTitle() {
    return this.$t("form_modal_title", {
      cta:
        this.editMode === FormModalEditingType.UPDATE
          ? this.$t("global_cta_save")
          : this.$t("global_cta_create"),
      title: this.title,
    }).toString();
  }

  get showErrorMessage() {
    return !!this.errorMessage;
  }

  isNumberField(inputType: FormModalInputTypeEnum): boolean {
    return (
      inputType === FormModalInputTypeEnum.INT ||
      inputType === FormModalInputTypeEnum.FLOAT
    );
  }

  async onSubmit(): Promise<void> {
    try {
      this.isLoading = true;
      this.$bvModal.hide(this.modalId);

      if (this.editMode === FormModalEditingType.UPDATE) {
        this.$emit("update");
      } else {
        this.$emit("create");
      }
    } catch (error) {
      this.isLoading = false;
    }
  }

  resetModal(): void {
    this.isLoading = false;
    this.errorMessage = "";
  }

  render() {
    return (
      <BModal
        id={this.modalId}
        modalClass={styles.formModal}
        bodyClass={styles.formModalBody}
        contentClass={styles.formModalContent}
        headerClass={styles.formModalHeader}
        footerClass={styles.formModalFooter}
        title={this.formTitle}
        onShow={this.resetModal}
        onHidden={this.resetModal}
        scopedSlots={{
          default: (slotProps: { ok: Function; cancel: Function }) => {
            const { cancel } = slotProps;
            return (
              <div>
                <BAlert show={this.showErrorMessage} variant="danger">
                  {this.errorMessage}
                </BAlert>

                <BForm role="form" novalidate show={false}>
                  {this.formData.map((item, index) => {
                    const key = `data-${item.label}-${index}`;
                    if (this.isNumberField(item.type)) {
                      return (
                        <FormInputWithLabel
                          key={key}
                          value={item.value}
                          onInput={withNumberInput((newVal) => {
                            item.value = newVal;
                          })}
                          min={item.min}
                          max={item.max}
                          label={item.label}
                          shouldValidate={true}
                          placeholderText={item.placeholderText || "data entry"}
                          inputType={item.type}
                        />
                      );
                    } else {
                      return (
                        <FormInputWithLabel
                          key={key}
                          value={item.value}
                          onInput={(val: any) => {
                            item.value = val;
                          }}
                          label={item.label}
                          placeholderText={item.placeholderText || "data entry"}
                          inputType={item.type}
                        />
                      );
                    }
                  })}
                  <Modal.ActionButtons>
                    <button
                      class="action-button__pill--outline"
                      type="reset"
                      onClick={(e: Event) => {
                        e.preventDefault();
                        cancel();
                      }}
                    >
                      {this.$t("global_cta_cancel").toString()}
                    </button>
                    <button
                      class={{
                        "action-button__pill--gradient": true,
                        "ml-3": true,
                        "spinner-padding": this.isLoading,
                      }}
                      type="submit"
                      onClick={(e: Event) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.onSubmit();
                      }}
                    >
                      {this.isLoading && (
                        <div class="default__spinner--small" role="status">
                          <BSpinner type="grow" label="Processing..." small />
                        </div>
                      )}
                      {this.editingModeButtonLabel}
                    </button>
                  </Modal.ActionButtons>
                </BForm>
              </div>
            );
          },
          // Empty modal-footer slot
          "modal-footer": () => <span></span>,
        }}
      />
    );
  }
}
