import { FieldDirectiveEnum } from "@/api/common-types";
import { ISchemaExplorerType } from "@/api/schema";
import { SortOrderType } from "@/generated/types";
import {
  BaseFieldTypeEnum,
  IApiState,
  IEnumState,
  IFieldType,
  IObjectTypeState,
} from "@/models";
import { upperCaseFirstLetter } from "@/utils";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export class ApiDataObjectTypeConfig extends Vue {
  @Prop({ required: true }) apiState: IApiState;
  @Prop({ required: true }) objectType: IObjectTypeState;
  @Prop() schemaFieldTypes?: Record<string, ISchemaExplorerType>;

  private get requiredGenericTypeFields() {
    return ["id", "ofType", "namespace"];
  }

  protected sortDirection = SortOrderType.DESC;

  protected get enumTypesAndValue() {
    return this.apiState.enums.reduce(
      (
        acc: Record<string, { text: string; value: string }[]>,
        enumType: IEnumState
      ) => {
        if (enumType.name) {
          acc[enumType.name] = enumType.values.map((val) => {
            return {
              text: val.name,
              value: val.name,
            };
          });
        }

        return acc;
      },
      {}
    );
  }

  protected get hasGenericType() {
    return Object.keys(this.schemaFieldTypes ?? []).some(
      (typeName) => typeName === "Type"
    );
  }

  protected get sortByField() {
    return (
      this.objectType.fields.find((field) =>
        field?.directives?.includes(FieldDirectiveEnum.SORTBY)
      )?.name ?? "updatedAt"
    );
  }

  private get filterInputParamName(): string {
    return `${upperCaseFirstLetter(this.objectType?.name)}ComparisonInput`;
  }

  protected get objectSchemaFieldTypes(): ISchemaExplorerType | undefined {
    return this.schemaFieldTypes?.[this.objectType?.name];
  }

  protected get objectFilterInputTypes(): ISchemaExplorerType | undefined {
    return this.schemaFieldTypes?.[this.filterInputParamName];
  }

  protected get objectSortRangeInputTypes(): ISchemaExplorerType | undefined {
    return this.schemaFieldTypes?.["RangeComparisonInput"];
  }

  protected get filterSchemaTypes(): ISchemaExplorerType[] | undefined {
    return (
      this.objectFilterInputTypes?.inputFields ??
      this.objectSchemaFieldTypes?.fields ??
      this.objectSortRangeInputTypes?.inputFields
    );
  }

  protected get requiredCreateInputParams(): string[] {
    return (
      this.schemaFieldTypes?.[
        `${upperCaseFirstLetter(this.objectType?.name)}Input`
      ]?.inputFields?.reduce((acc: any[], field: ISchemaExplorerType) => {
        if (field.type?.kind === "NON_NULL") {
          acc.push(field.name);
        }

        return acc;
      }, []) ?? []
    );
  }

  protected get requiredDeleteInputParams(): string[] {
    return this.hasGenericType
      ? this.requiredGenericTypeFields
      : this.schemaFieldTypes?.[
          `${upperCaseFirstLetter(this.objectType?.name)}DeleteInput`
        ]?.inputFields?.reduce((acc: any[], field: ISchemaExplorerType) => {
          if (field.type?.kind === "NON_NULL") {
            acc.push(field.name);
          }

          return acc;
        }, []) ?? [];
  }

  protected get requiredUpdateInputParams(): string[] {
    return this.hasGenericType
      ? this.requiredGenericTypeFields
      : this.schemaFieldTypes?.[
          `${upperCaseFirstLetter(this.objectType?.name)}UpdateInput`
        ]?.inputFields?.reduce((acc: string[], field: any) => {
          if (field?.type?.kind === "NON_NULL") {
            acc.push(field.name);
          }

          return acc;
        }, []) ?? [];
  }

  protected getTypeOptions(type: IFieldType) {
    return type === BaseFieldTypeEnum.Boolean
      ? [
          { text: "true", value: "true" },
          { text: "false", value: "false" },
        ]
      : type && this.enumTypesAndValue[type]
      ? this.enumTypesAndValue[type]
      : [];
  }

  protected handleSortOrderChange(sortOrder: SortOrderType) {
    this.sortDirection = sortOrder;
  }

  protected get isActiveTypeUnpublished() {
    return (
      this.schemaFieldTypes &&
      this.schemaFieldTypes[this.objectType?.name] === undefined
    );
  }
}
