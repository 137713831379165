import { IRangeComparisonInput } from "@/generated/types";
import { IFilterValueState, ISortRangeValueState } from "@/models/filters";
import store from "@/store/store";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";

export interface IApiDataState {
  activeFilters: IFilterValueState | null;
}

@Module({ dynamic: true, store, name: "apiData", namespaced: true })
class ApiData extends VuexModule implements IApiDataState {
  activeFilters: IFilterValueState | null = null;
  activeSortRange: IRangeComparisonInput | null = null;

  @Mutation
  private SET_APPLIED_FILTERS(activeFilters: IFilterValueState) {
    this.activeFilters = activeFilters;
  }

  @Action
  setActiveFilters(activeFilters: IFilterValueState) {
    this.SET_APPLIED_FILTERS(activeFilters);
  }

  @Mutation
  private SET_APPLIED_SORTRANGE(activeSortRange: ISortRangeValueState) {
    const comparisonInputList = Object.values(activeSortRange);
    this.activeSortRange =
      comparisonInputList.length > 0 ? comparisonInputList[0] : null;
  }

  @Action
  setActiveSortRange(activeSortRange: ISortRangeValueState) {
    this.SET_APPLIED_SORTRANGE(activeSortRange);
  }
}

export const ApiDataModule = getModule(ApiData);
