import { IObjectTypeState } from "@/models";
import { ICustomerListVariables } from "../shared/types";
import { DedicatedApi } from "./dedicated-api";

export const API_KEY_TYPE = "ApiKey";

export interface IApiKey {
  key: string;
  description: string;
  id: string;
  expires: number;
  deletes: number;
  createdAt: string;
  updatedAt?: string | null;
}

const apiKeyType = (): IObjectTypeState => {
  return {
    id: "",
    name: API_KEY_TYPE,
    targets: [],
    parents: { first: null, second: null },
    fields: [],
    directives: [],
    resolvers: [],
    selected: false,
  };
};

export class CustomerApiApiKeys {
  constructor(private customerApi: DedicatedApi) {}

  watchCloudFunctions(variables: ICustomerListVariables) {
    return this.customerApi.watchListObjects<IApiKey>(apiKeyType(), variables);
  }

  batchDeleteApiKeys(
    input: Record<string, unknown>[],
    initialVariables: ICustomerListVariables
  ) {
    return this.customerApi.batchDeleteObjects(
      apiKeyType(),
      {
        input,
      },
      this.customerApi.cacheUpdates.clearAllAfterBatchDeletionFactory(
        apiKeyType(),
        initialVariables
      )
    );
  }

  createApiKey(
    input: Record<string, unknown>,
    initialVariables: ICustomerListVariables
  ) {
    return this.customerApi.createObject(
      apiKeyType(),
      {
        input,
      },
      this.customerApi.cacheUpdates.addToListFrontAfterCreationFactory(
        apiKeyType(),
        initialVariables
      )
    );
  }

  updateApiKey(input: Record<string, unknown>) {
    return this.customerApi.updateObject(apiKeyType(), {
      input,
    });
  }
}
