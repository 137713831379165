import {
  extractData,
  extractAndFilterItemsOrProvideDefault,
  extractPropOrThrow,
} from "./utils";
import { queryFlowRunsLogsByFlowRun } from "@/api/flow-run-log";
import { FetchPolicy } from "apollo-boost";

export async function getFlowRunLogByFlowRunId(
  flowRunId: string,
  fetchPolicy?: FetchPolicy
) {
  const response = await queryFlowRunsLogsByFlowRun(
    { id: flowRunId },
    fetchPolicy
  );

  return extractAndFilterItemsOrProvideDefault(
    extractPropOrThrow(extractData(response), "listFlowRunLogsByFlowRun")
  );
}
