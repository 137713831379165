import {
  ApiCreationTypeEnum,
  BillingIntervalEnum,
  BundleTypeEnum,
  ProductTypeEnum,
  PublishingStateEnum,
  UsageTypeEnum,
} from "@/api/common-types";
import {
  getCheckoutSessionsByApi,
  ICheckoutSessionState,
} from "@/models/checkout-session";
import { IPreselectedLineItems } from "@/models/product";
import {
  getProductBundles,
  IPriceState,
  IProductBundleMap,
  IProductState,
} from "@/models/product-bundle";
import { CheckoutModule } from "@/store/modules/checkout";
import { NavModule } from "@/store/modules/nav";
import { OrgModule } from "@/store/modules/org";
import Vue from "vue";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";

// Child components
import ApiDataManager from "@/components/api-data/api-data-manager";
import { ButtonTextWithHint } from "@/components/shared/ButtonTextWithHint";
import BundleWidgetGroup from "@/components/widgets/BundleWidgetGroup.component";
import ProductWidgetGroup from "@/components/widgets/ProductWidgetGroup.component.vue";
import WizardStep from "@/components/wizard/WizardStep.component";
import {
  BAlert,
  BButton,
  BContainer,
  BFormCheckbox,
  BRow,
  BSpinner,
} from "@/lib/typed-bootstrap";
import CheckoutSession from "@/mixins/CheckoutSession.mixin";
import * as tsx from "vue-tsx-support";
import BundleBenefitsSection from "./BundleBenefitsSections.component";
import "./ProjectBundlesOverview.view.scss";

@Component
export default class ProjectBundlesOverview extends mixins(CheckoutSession) {
  private isBillingCycleYearly = true;
  private isSaving = false;
  private isLoadingProductBundles = false;
  private errorMessage = "";
  private productBundleMap: IProductBundleMap | null = null;
  private creationType = ApiCreationTypeEnum.PUBLISH;
  private apiDataManager = Vue.observable(new ApiDataManager());

  private get apiId(): string {
    return this.$route.params.apiId;
  }

  @Watch("apiId", { immediate: true }) async onApiIdChange() {
    if (!this.apiId) {
      return;
    }

    await this.apiDataManager.onApiIdChange(this.apiId);
    this.onApiFetch();
  }

  private get activeBundleType(): BundleTypeEnum {
    return (
      this.bundleConfigs?.find(({ product }) => product.selected === true)
        ?.product?.productBundleType ?? BundleTypeEnum.STARTER
    );
  }

  private set activeBundleType(bundleType: BundleTypeEnum) {
    this.bundleConfigs?.forEach(
      ({ product }) =>
        (product.selected = product.productBundleType === bundleType)
    );
  }

  private get apiName(): string | undefined {
    return this.apiDataManager.apiState?.name ?? undefined;
  }

  private get bundleConfigs() {
    return Object.entries(this.productBundleMap ?? {})
      .map(([, v]) => ({
        product: v.products.filter((p) => p.type === ProductTypeEnum.BUNDLE)[0],
        freeTiers: v.freeTiers,
      }))
      .filter((x) => x.product);
  }

  private get isApiUnpublished(): boolean {
    return (
      this.apiDataManager.apiState?.state === PublishingStateEnum.INITIALIZED
    );
  }

  private get paymentCancelUrl() {
    return new URL(
      `${this.$router.currentRoute.path}?session_id={CHECKOUT_SESSION_ID}`,
      window.location.href
    ).href;
  }

  private get paymentSuccessUrl() {
    const component = this.$router.resolve({
      name: "project-setup-publishing",
      params: { apiId: this.apiId },
    });
    return new URL(
      `${component.route.path}?session_id={CHECKOUT_SESSION_ID}`,
      window.location.href
    ).href;
  }

  private get billingInterval() {
    return this.isBillingCycleYearly
      ? BillingIntervalEnum.YEAR
      : BillingIntervalEnum.MONTH;
  }

  private get productBundle() {
    return this.productBundleMap?.[this.activeBundleType];
  }

  private get products() {
    return this.productBundle?.products;
  }

  private get meteredLineItems() {
    return (
      this.selectedProducts.reduce((acc, product) => {
        if (
          this.billingInterval === BillingIntervalEnum.YEAR &&
          product.usageType === UsageTypeEnum.METERED
        ) {
          const pricings = product.pricings[BillingIntervalEnum.MONTH] ?? [];
          const price: IPriceState | undefined = pricings[0];
          if (price?.paymentProviderId) {
            acc.push({ price: price.paymentProviderId });
          }
        }

        return acc;
      }, [] as { price: string }[]) ?? []
    );
  }

  private get preselectedProductNames() {
    return [];
  }

  private get selectedLineItems() {
    return this.selectedProducts.reduce((acc, product: IProductState) => {
      const price: IPriceState | undefined =
        product.pricings[this.billingInterval]?.[0];
      if (price?.paymentProviderId && price?.usageType) {
        acc.push({
          price: price.paymentProviderId,
          ...(price.usageType === UsageTypeEnum.LICENSED && {
            quantity: 1,
          }),
        });
      }

      return acc;
    }, [] as { price: string; quantity?: number }[]);
  }

  private get selectedProducts() {
    return (
      this.productBundle?.products?.reduce((acc, product: IProductState) => {
        if (product.selected) {
          acc.push(product);
        }

        return acc;
      }, [] as IProductState[]) ?? []
    );
  }

  private get selectedProductNames() {
    return this.selectedProducts.reduce((acc, product: IProductState) => {
      product.name && acc.push(product.name);
      return acc;
    }, [] as string[]);
  }

  private get showErrorMessage(): boolean {
    return !!this.errorMessage;
  }

  private async onApiFetch() {
    try {
      this.isLoadingProductBundles = true;
      NavModule.setCurrentApiName(this.apiName ?? "");
      const previousCheckoutSessions = await getCheckoutSessionsByApi(
        this.$route.params.apiId
      );

      if (previousCheckoutSessions.length > 0) {
        const session = previousCheckoutSessions[0] as ICheckoutSessionState;
        if (
          session.expiresAt > Math.floor(Date.now() / 1000) &&
          session.sessionId
        ) {
          CheckoutModule.handleCheckoutSessionResponse({
            apiId: this.apiId,
            session,
          });
        }
      }

      // Extract lineItems from cancelled checkout session if query-param 'session_id' exists
      let lineItems: any[] = [];
      const cancelledSessionId = this.$route.query.session_id;
      if (!Array.isArray(cancelledSessionId)) {
        const cancelledSession = CheckoutModule.checkoutSessions[
          cancelledSessionId
        ] as ICheckoutSessionState;
        if (cancelledSession) {
          this.activeBundleType =
            (cancelledSession.bundleType as BundleTypeEnum) ??
            BundleTypeEnum.STARTER;
          lineItems = cancelledSession.lineItems
            ? JSON.parse(cancelledSession.lineItems)
            : [];
        }
      }

      const preselectedLineItems = lineItems.reduce(
        (acc: IPreselectedLineItems, item) => {
          acc[item.price] = +item.quantity ?? 1;
          return acc;
        },
        {}
      );

      this.productBundleMap = await getProductBundles(
        preselectedLineItems,
        this.preselectedProductNames
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoadingProductBundles = false;
    }
  }

  private async onSubmit(): Promise<void> {
    try {
      if (
        this.creationType === ApiCreationTypeEnum.PUBLISH &&
        (this.apiDataManager.apiState?.types?.length === 0 ||
          (this.apiDataManager.apiState?.types?.length === 1 &&
            this.apiDataManager.apiState?.types[0]?.name?.length <= 0))
      ) {
        throw new Error(this.$t("error_api_create_requires_types").toString());
      }

      this.isLoading = true;

      const session = await this.createCheckoutSession({
        orgId: OrgModule.id,
        paymentProviderId: OrgModule.billingAccount?.billingAddress?.country
          ? OrgModule.billingAccount?.paymentProviderId ?? ""
          : "",
        cancelUrl: this.paymentCancelUrl,
        successUrl: this.paymentSuccessUrl,
        metadata: JSON.stringify(this.meteredLineItems),
        lineItems: JSON.stringify(this.selectedLineItems),
        products: this.selectedProductNames,
        apiId: this.apiId,
        description: `Subscription: ${this.activeBundleType} ${this.apiName}`,
        bundleType: this.activeBundleType,
      });

      if (session?.sessionId && session?.sessionUrl) {
        if (window.Cypress) {
          this.$router.push({
            name: "project-setup-publishing",
            params: { apiId: this.apiId },
            query: { session_id: session?.sessionId },
          });
        } else {
          window.location.href = session.sessionUrl;
        }
      } else {
        this.$router.push({
          name: "project-setup-billing",
          params: { apiId: this.apiId },
        });
      }
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isLoading = false;
    }
  }

  private onBillingCycleChange() {
    this.isBillingCycleYearly = !this.isBillingCycleYearly;
  }

  render() {
    return (
      <WizardStep
        class="wizardStep"
        toLocationOnClose={{
          name: "project-data",
          params: { apiId: this.apiId },
        }}
      >
        <template slot="wizardName">
          {this.$t("api_publish_wizard_name", { step: 3, stepCount: 3 })}
        </template>
        <template slot="header"> {this.$t("bundle_view_title")} </template>
        <template slot="description">
          {" "}
          {this.$t("bundle_view_subtitle")}{" "}
        </template>
        <BContainer class="mt-0">
          <BAlert show={this.showErrorMessage} variant="danger">
            {this.errorMessage}
          </BAlert>
          <div class="billingCycleSwitch">
            <span
              class={[
                "billingCycleMonthlyLabel",
                !this.isBillingCycleYearly && "active",
              ]}
            >
              {this.$t("bundle_billing_timeframe_monthly")}
            </span>
            <BFormCheckbox
              name="billing"
              class={[
                "billingCycleYearlyLabel",
                this.isBillingCycleYearly && "active",
              ]}
              checked={this.isBillingCycleYearly}
              onChange={this.onBillingCycleChange}
              switch
              size="lg"
            >
              {this.$t("bundle_billing_timeframe_yearly")}
            </BFormCheckbox>
            <span
              class={[
                "billingCycleBenefit",
                this.isBillingCycleYearly && "active",
              ]}
            >
              {this.$t("bundle_billing_yearly_discount")}
            </span>
          </div>
          <BundleWidgetGroup
            bundleConfigs={this.bundleConfigs}
            billingInterval={this.billingInterval}
          />

          <BundleBenefitsSection bundleConfigs={this.bundleConfigs} />

          <ProductWidgetGroup
            products={this.products}
            bundleType={this.activeBundleType}
            billingInterval={this.billingInterval}
          />
          <BRow class="buttons__section">
            <BButton
              type="submit"
              onClick={tsx.modifiers.prevent(this.onSubmit)}
              class="default__button mt-3"
              data-testid="subscribe-button"
              disabled={
                this.isLoading ||
                this.isSaving ||
                this.apiDataManager.isFetching ||
                this.isLoadingProductBundles
              }
              size="lg"
              block
            >
              {this.isLoading ? (
                <div class="d-flex default__spinner--centered" role="status">
                  <BSpinner
                    type="grow"
                    label="Spinning"
                    show={false}
                  ></BSpinner>
                </div>
              ) : (
                <ButtonTextWithHint>
                  <template slot="default">
                    {this.$t("global_cta_subscribe")}
                  </template>
                </ButtonTextWithHint>
              )}
            </BButton>
          </BRow>
        </BContainer>
      </WizardStep>
    );
  }
}
