import {
  CustomerApiCloudFunctions,
  DedicatedApi,
  ICloudFunction,
  ICustomerListVariables,
} from "@/api-client";
import plusIcon from "@/assets/img/plus-icon-placeholder.png";
import { SortOrderType } from "@/generated/types";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import { BButton } from "@/lib/typed-bootstrap";
import { IEnumState, IObjectTypeState } from "@/models";
import { ApiSchema } from "@/models/api-schema";
import { ListDataManager } from "@/utils/list-data-manager";
import {
  ArchiveTypeEnum,
  MutationTypeEnum,
  PipelineStepEnum,
  RuntimeEnum,
} from "@graphapi-io/api-declaration";
import { ApolloClient } from "apollo-boost";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { ObjectTypeTrigger } from "./ObjectTypeTrigger.component";

@Component
export class ObjectTypeTriggers extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: ObjectTypeTriggers["value"];
    allTypes: ObjectTypeTriggers["allTypes"];
    allEnumTypes?: ObjectTypeTriggers["allEnumTypes"];
    apiClient: ObjectTypeTriggers["apiClient"];
    apiSchemaHandler: ObjectTypeTriggers["apiSchemaHandler"];
  }>;

  @Prop({ required: true }) value: IObjectTypeState;
  @Prop({ required: true }) allTypes: IObjectTypeState[];
  @Prop({ required: false, default: [] }) allEnumTypes: IEnumState[];
  @Prop({ required: true }) apiClient: ApolloClient<unknown>;
  @Prop({ required: true }) apiSchemaHandler: ApiSchemaHandler | null;

  private get listDataManager() {
    return Vue.observable(
      new ListDataManager<
        { items: ICloudFunction[]; nextToken: string | null },
        ICustomerListVariables
      >(
        this.customerApiCloudFunctions.watchCloudFunctions.bind(
          this.customerApiCloudFunctions
        )
      )
    );
  }

  @Watch("apiSchemaHandler.inProgress", { immediate: true })
  onApiSchemaHandlerInitialized() {
    if (this.apiSchemaHandler && !this.apiSchemaHandler?.inProgress) {
      this.listDataManager.subscribeToList({
        limit: 100,
        nextToken: null,
        filter: undefined,
        sortOrder: SortOrderType.DESC,
        sortRange: undefined,
      });
    }
  }

  get schemaFieldTypes() {
    return this.apiSchemaHandler?.inProgress || !this.apiSchemaHandler
      ? {}
      : this.apiSchemaHandler?.schemaFieldTypes;
  }

  get customerApi() {
    return new DedicatedApi(
      this.apiClient,
      this.schemaFieldTypes,
      new ApiSchema(
        { types: this.allTypes, enums: this.allEnumTypes },
        this.schemaFieldTypes
      )
    );
  }

  get customerApiCloudFunctions() {
    return new CustomerApiCloudFunctions(this.customerApi);
  }

  get canAddNext() {
    return (
      this.value.resolvers.length === 0 ||
      this.value.resolvers[this.value.resolvers.length - 1].name !== ""
    );
  }

  private onAddTrigger(_ev: MouseEvent) {
    this.value.resolvers.push({
      name: "",
      archive: "",
      archiveType: ArchiveTypeEnum.S3BUCKET,
      handler: "",
      step: PipelineStepEnum.BEFORE,
      runtime: RuntimeEnum.NODEJS_18_X,
      types: [
        MutationTypeEnum.CREATE,
        MutationTypeEnum.UPDATE,
        MutationTypeEnum.DELETE,
      ],
      actions: [],
    });
  }

  private onDeleteTrigger(index: number) {
    this.value.resolvers = this.value.resolvers.filter((_, i) => i !== index);
  }

  render() {
    return (
      <div class="ml-0">
        <div>
          {this.value.resolvers.map((resolver, index) => (
            <ObjectTypeTrigger
              index={index}
              resolver={resolver}
              onDelete={() => this.onDeleteTrigger(index)}
              key={resolver.name}
              cloudFunctions={this.listDataManager.data ?? []}
            >
              {resolver.name}
            </ObjectTypeTrigger>
          ))}
        </div>
        <BButton
          class="create__button--shallow mt-2"
          type="button"
          onClick={tsx.modifiers.prevent(this.onAddTrigger)}
          disabled={!this.canAddNext}
        >
          <div class="add-button__icon">
            <img src={plusIcon} />
          </div>
          <div class="button__icon-text">
            {this.$t("model_widget_add_trigger")}
          </div>
        </BButton>
      </div>
    );
  }
}
